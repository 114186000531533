const skills = [
	{
		category: 'Software Development Skills',
		list: [
			'React',
			'Node.js',
			'HTML',
			'CSS',
			'JavaScript/jQuery',
			'Python',
			'Java',
			'C++',
			'SQL Fundamentals',
			'Scheme'
		]
	},
	{
		category: 'Operating Systems',
		list: [
			'Windows',
			'Mac OS X',
			'Ubuntu'
		]
	},
	{
		category: 'Applications',
		list: [
			'VSCode',
			'Figma',
			'Invision',
			'Zeplin',
			'Adobe InDesign',
			'Adobe Dreamweaver',
			'Adobe Fireworks',
			'MS Office',
			'Microsoft Project',
			'RStudio'
		]
	},
	{
		category: 'Certifications',
		list: [
			'MS Office 2010',
			'CompTIA A+',
			'Oracle SQL',
			'Java SE 6'
		]
	},
	{
		category: 'Other',
		list: [
			'Git',
			'Bootstrap',
			'Arduino (beginner)',
			'Project Management'
		]
	}
];

export default skills;
