const hobbies = {
	crossStitch: [
		{
			name: 'Potted Hydrangeas',
			fileName: 'potted_hydrangeas.jpg',
			altText: 'Purple-blue hydrangeas in a planter',
			size: {
				height: 1008,
				width: 756
			}
		},
		{
			name: 'Santa\'s Workshop',
			fileName: 'santas_workshop.jpg',
			altText: '',
			size: {
				height: 1024,
				width: 768
			}
		},
		{
			name: 'Fan-f***ing-tastic',
			fileName: 'fantastic.jpg',
			altText: '',
			size: {
				height: 832,
				width: 810
			}
		},
		{
			name: 'I can\'t adult today',
			fileName: 'cant_adult.jpg',
			altText: 'Embroidery hoop with finished cross stitched text reading "I can\'t adult today"',
			size: {
				height: 688,
				width: 639
			}
		},
		{
			name: 'Mason Jar Flowers',
			fileName: 'mason_jar_flowers.jpg',
			altText: 'Cross stitch depicting three bouquets of wild flowers in mason jars',
			size: {
				height: 941,
				width: 724
			}
		},
		// {
		//   name: 'Flowers of the Seasons',
		//   fileName: 'seasons_flowers.jpg',
		//   altText: 'Cross stitch of a variety of different flowers arranged in a wreath organized by the season the flowers bloom.',
		//   size: {
		//     height: 687,
		//     width: 724
		//   }
		// },
		{
			name: 'Cruel Summer',
			fileName: 'cruel_summer.jpg',
			altText: 'Cross stitch of lyrics from Taylor Swift\'s song, "Cruel Summer". The lyrics read "I love you, ain\'t that the worst thing you ever heard?"',
			size: {
				height: 941,
				width: 724
			}
		},
		{
			name: 'Hang in there',
			fileName: 'hang_in_there.jpg',
			altText: 'Cross stitch of a hanging plant with the phrase "Hang in there" written below',
			size: {
				height: 724,
				width: 724
			}
		},
		{
			name: 'Beer mug',
			fileName: 'beer_mug.jpg',
			altText: 'Framed cross stitch of words related to beer that are layed out in the shape of a beer mug',
			size: {
				height: 941,
				width: 724
			}
		},
		{
			name: 'Lighthouses',
			fileName: 'lighthouses.jpg',
			altText: '',
			size: {
				height: 1129,
				width: 544
			}
		},
	]
};

export default hobbies;
