const DarkTheme = {
	palette: {
		type: 'dark',
		primary: {
			light: '#fff1ff',
			main: '#e1bee7',
			dark: '#af8eb5',
			contrastText: '#000',
		},
		secondary: {
			light: '#fff7ff',
			main: '#d1c4e9',
			dark: '#a094b7',
			contrastText: '#fff',
		},
		background: {
			default: '#212121'
		},
		typography: {
			primary: '#000'
		}
	}
};

export default DarkTheme;
